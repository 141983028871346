import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';

import { WorkspaceLoaderDialogComponent } from './workspace-loader-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceLoaderService {
  private unsubscribe = new Subject<void>();

  private activityFeedLoadedSubject = new BehaviorSubject<boolean>(false);
  private topNavLoadedSubject = new BehaviorSubject<boolean>(false);
  private sideNavLoadedSubject = new BehaviorSubject<boolean>(false);

  private dialogRef: DialogRef<void, WorkspaceLoaderDialogComponent> | null = null;

  constructor(private readonly dialog: Dialog) {
    this.loadingStatus$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(([activityFeedLoaded, topNavLoaded, sideNavLoaded]) => {
        this.checkAllLoaded(activityFeedLoaded, topNavLoaded, sideNavLoaded);
      });
  }

  get loadingStatus$(): Observable<[boolean, boolean, boolean]> {
    return combineLatest([
      this.activityFeedLoadedSubject,
      this.topNavLoadedSubject,
      this.sideNavLoadedSubject,
    ]);
  }

  setActivityFeedLoaded(value: boolean) {
    this.activityFeedLoadedSubject.next(value);
  }

  setTopNavLoaded(value: boolean) {
    this.topNavLoadedSubject.next(value);
  }

  setSideNavLoaded(value: boolean) {
    this.sideNavLoadedSubject.next(value);
  }

  resetState() {
    this.activityFeedLoadedSubject.next(false);
    this.topNavLoadedSubject.next(false);
    this.sideNavLoadedSubject.next(false);
  }

  open() {
    this.dialogRef = this.dialog.open(WorkspaceLoaderDialogComponent);
  }

  protected checkAllLoaded(activityFeedLoaded: boolean, topNavLoaded: boolean, sideNavLoaded: boolean) {
    if (activityFeedLoaded && topNavLoaded && sideNavLoaded) {
      if (this.dialogRef) {
        this.dialogRef.close();
      }
      this.resetState();
    }
  }
}
