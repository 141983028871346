import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Company } from '@site-mate/dashpivot-shared-library';

@Injectable()
export class NavService {
  private subject = new BehaviorSubject<any>(true);
  public toggleEvent = this.subject.asObservable();

  private updateCompanyMetadataSubject = new BehaviorSubject({});
  public onCompanyMetadataUpdate = this.updateCompanyMetadataSubject.asObservable();

  updateCompanyMetadataNav(company: Company) {
    this.updateCompanyMetadataSubject.next(company);
  }

  show() {
    this.subject.next(true);
  }

  hide() {
    this.subject.next(false);
  }
}
