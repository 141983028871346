import { Inject, Injectable } from '@angular/core';

import { WINDOW } from 'app/shared/factory/window.factory';

@Injectable({ providedIn: 'root' })
export class SessionStorageService {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  get sessionStorage() {
    return this.window.sessionStorage;
  }

  getItem(key: string) {
    return this.window.sessionStorage.getItem(key);
  }

  setItem(key: string, value: string) {
    this.window.sessionStorage.setItem(key, value);
    return this;
  }

  removeItem(key: string) {
    this.window.sessionStorage.removeItem(key);
  }
}
