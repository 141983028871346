import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { flatten, flatMap } from 'lodash-es';
import { of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { NoWorkspaceFoundService } from 'app/org/no-workspace-found/no-workspace-found.service';
import { TeamWeb } from 'app/shared/model/team.model';
import { LocalStorageService } from 'app/shared/service/local-storage.service';
import { TeamService } from 'app/shared/service/team.service';
import { UserService } from 'app/user/user.service';

import { CompanyWeb } from '../model/company.model';
import { ProjectWeb } from '../model/project.model';

@Injectable({
  providedIn: 'root',
})
export class LastVisitedHomeService {
  private readonly lastVisitedKey = 'lastVisited';

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly teamService: TeamService,
    private readonly userService: UserService,
    private readonly folderNavBarService: FolderNavBarService,
    private readonly noWorkspaceFoundService: NoWorkspaceFoundService,
  ) {
    this.teamService.currentTeam
      .pipe(filter((value) => !!value?._id && !value?.isTemplateLibrary))
      .subscribe((team: TeamWeb) => {
        if (team.isCompany) {
          this.logLastCompany(team._id);
        } else if (team.isProject) {
          this.logLastProject(team._id);
        } else {
          this.logLastTeam(team._id);
        }
      });
  }

  logLastCompany(id) {
    this.logLastOrg('company', id);
  }

  logLastProject(id) {
    this.logLastOrg('project', id);
  }

  logLastTeam(id) {
    this.logLastOrg('team', id);
  }

  goToFirstPossibleTeam(router: Router, refreshLastVisited = false) {
    if (refreshLastVisited) {
      window.localStorage.removeItem(this.lastVisitedKey);
    }

    const user = this.userService.getCurrentUser();

    if (user.isGlobalAdmin) {
      const firstTeamId = user.standardUserOf.teams[0];
      return of(this.goToLastOrDefault(router, firstTeamId, null));
    }

    return this.folderNavBarService.getUserCompanies().pipe(
      take(1),
      switchMap((companies) => this.getClosestFolder(companies, router)),
    );
  }

  private getClosestFolder(companies: CompanyWeb[], router: Router) {
    if (!companies.length) {
      return of(this.noWorkspaceFoundService.openModal());
    }

    this.folderNavBarService.sortCompanyTree(companies[0].projects);
    const firstTeamId = this.getFirstTeam(companies)?._id;
    if (firstTeamId) {
      return of(this.goToLastOrDefault(router, firstTeamId, null, 'team'));
    }

    const firstProjectId = this.getFirstProject(companies)?._id;
    if (firstProjectId) {
      return of(this.goToLastOrDefault(router, firstProjectId, null, 'project'));
    }

    const firstCompanyId = this.getFirstCompany(companies)?._id;
    if (firstCompanyId) {
      return of(this.goToLastOrDefault(router, firstCompanyId, null, 'company'));
    }

    throw new Error('No access to any team, project or company.');
  }

  private getFirstTeam(companies: CompanyWeb[]) {
    const projectWithTeams = flatMap(companies, (c) => c.projects).find((p) => p.teams?.length);

    return projectWithTeams ? projectWithTeams.teams[0] : null;
  }

  private getFirstProject(companies: CompanyWeb[]) {
    const projectsUserIsController = this.userService.getCurrentUser().projectControllerOf;
    const projects = flatten(companies.map((company) => company.projects)) as ProjectWeb[];
    return projects.find((project) => projectsUserIsController.some((id) => id === project._id));
  }

  private getFirstCompany(companies: CompanyWeb[]) {
    const companiesUserIsController = this.userService.getCurrentUser().companyControllerOf;
    return companies.find((company) => companiesUserIsController.some((id) => id === company._id));
  }

  goToLastOrDefault(
    router: Router,
    folderId: string,
    queryParams: Params,
    folderLevel: 'company' | 'project' | 'team' = 'team',
  ) {
    const routePaths = {
      company: ['/', 'home', 'companies'],
      project: ['/', 'home', 'projects'],
      team: ['/', 'home', 'teams'],
    };

    try {
      const lastVisited = JSON.parse(window.localStorage[this.lastVisitedKey]);
      const { userId, orgType, orgId }: { userId?: string; orgType?: string; orgId?: string } = lastVisited;

      if (userId === this.userService.getCurrentUser().id && orgType && orgId) {
        void router.navigate([...routePaths[orgType], orgId], { queryParams });
      } else {
        void router.navigate([...routePaths[folderLevel], folderId], { queryParams });
      }
    } catch {
      void router.navigate([...routePaths[folderLevel], folderId], { queryParams });
    }
  }

  private logLastOrg(type, id) {
    const user = this.userService.getCurrentUser();
    const lastVisited = {
      userId: user.id,
      orgType: type,
      orgId: id,
    };

    const processedLastVisited = JSON.stringify(lastVisited);
    this.localStorageService.setItem(this.lastVisitedKey, processedLastVisited);
  }
}
