import { DialogRef } from '@angular/cdk/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cc-workspace-loader-dialog',
  templateUrl: './workspace-loader-dialog.component.html',
})
export class WorkspaceLoaderDialogComponent implements OnInit {
  constructor(
    public dialogRef: DialogRef<void>,
    private overlay: Overlay,
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    this.dialogRef.overlayRef.updatePositionStrategy(
      this.overlay.position().global().top('130px').centerHorizontally(),
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
