import { AbstractControl } from '@angular/forms';

import { AppHierarchy } from './app-hierarchy.enum';

export class RegexpMatchers {
  // eslint-disable-next-line prefer-regex-literals
  static readonly doNotAcceptWithoutAlphanumeric = new RegExp('^.*[a-zA-Z0-9].*$');
}

type ValidationResult = { [key: string]: any } | null;
type ValidatorFn = (control: AbstractControl) => ValidationResult;

export function validateFolderName(hierarchy: AppHierarchy): ValidatorFn {
  const specialCharactersPattern = '@ \\$%&\\*\\(\\)\\-\\+\\\\_\\\\|\\.,<>/’“;:{}\\[\\]';
  const alphanumericPattern = 'a-zA-Z0-9';
  const allSupportedCharactersRegex = new RegExp(`^[${alphanumericPattern}${specialCharactersPattern}]*$`);
  const folder = hierarchy.charAt(0).toUpperCase() + hierarchy.slice(1);

  return (control: AbstractControl): ValidationResult => {
    const value = control.value.trim();
    const errorMessage = (message: string) => ({ valid: false, message });

    if (!value) {
      return errorMessage(`${folder} name is required`);
    }

    if (!new RegExp(`[${alphanumericPattern}]`).test(value)) {
      return errorMessage(`${folder} name should have one or more alphanumeric characters`);
    }

    if (!allSupportedCharactersRegex.test(value)) {
      const message = `Some special characters are not supported.<br/> We support: @ $ % & * ( ) - + _ \\ | . , < > / ’ “ ; : { } [ ]`;
      return errorMessage(message.trim());
    }

    return null;
  };
}
