export const introVideos = [
  {
    youtubeTitle: 'Getting Started with Dashpivot',
    datePublished: '13/5/2024',
    verticalCode: '',
    verticalId: '',
    vertical: 'Other',
    link: 'https://youtu.be/PkCXYOHUXfY',
  },
  {
    youtubeTitle: 'Getting Started with Dashpivot for Civil Construction',
    datePublished: '5/8/2024',
    verticalCode: 'V01',
    verticalId: '649b81add8b12efa397f6896',
    vertical: 'Civil Construction',
    link: 'https://youtu.be/Y7Houn9xwVM',
  },
  {
    youtubeTitle: 'Getting Started with Dashpivot for Building Construction',
    datePublished: '5/8/2024',
    verticalCode: 'V02',
    verticalId: '649b81add8b12efa397f6886',
    vertical: 'Building Construction',
    link: 'https://youtu.be/G2x-vNYPP8I',
  },
];
