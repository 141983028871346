<gw-modal [dismissable]="dismissable" [title]="title" class="intro-video-modal" (dismissEvent)="onDismiss()">
  <div class="sm-flex sm-flex-col sm-gap-4 sm-text-center">
    <p i18n>To get you up and running in minutes, check out our short walkthrough video!</p>

    <cc-youtube-video-player
      [videoId]="INTRO_VIDEO_ID"
      (onStateChange)="onVideoStateChange($event)"
    ></cc-youtube-video-player>

    <button
      gw-button
      *ngIf="!dismissable; else noCTAClickEvent"
      type="button"
      color="blue"
      size="large"
      data-test="cta-button"
      ccSegmentCTA
      ctaContext="Sign Up Intro Video"
      text="Get Started"
      class="sm-mx-auto sm-w-2/4"
      (click)="onCTAClick()"
    ></button>

    <ng-template #noCTAClickEvent>
      <button
        gw-button
        type="button"
        color="blue"
        size="large"
        data-test="cta-button"
        text="Watch Later"
        class="sm-mx-auto sm-w-2/4"
        (click)="onCTAClick()"
      ></button>
    </ng-template>
  </div>
</gw-modal>
