<h5 class="h5 sm-text-center sm-font-bold" id="welcome-message" i18n>Welcome to Dashpivot</h5>
<img src="./assets/images/signup/celebrate.svg" alt="people celebrating your signup" id="celebration-image" />
<span>
  <p class="default-paragraph sm-text-left">
    To continue setting up your account, you’ll need to login to www.dashpivot.com on a computer. There you
    can create projects and templates, and invite your team mates.
  </p>
  <p class="default-paragraph sm-text-left">
    Once setup, you’ll want to download the Dashpivot app to take photos and complete forms on your phone or
    tablet.
  </p>
</span>
<a
  href="https://api.dashpivot.com/mobile/download-mobile-app"
  class="button-regular button-blue"
  i18n
  id="download-mobile-app"
  _target="top"
  rel="noopener"
  ccSegmentCTA="Download App Link"
  ctaContext="Self Sign Up User"
>
  Download the Dashpivot app
</a>
