<form
  class="form sm-space-y-4"
  #companyInfoForm="ngForm"
  name="companyInfoForm"
  (ngSubmit)="submitDetails(companyInfoForm)"
>
  <div *ngIf="!dragStart" class="sm-space-y-2 sm-text-center">
    <h5 class="sm-text-xl sm-font-bold" i18n>Setup your workspace</h5>
    <p i18n>
      Upload your logo so all your forms are branded<br />
      <span class="text-muted">(This can be changed later)</span>
    </p>
  </div>

  <!-- Dropzone -->
  <div class="dropzone-wrapper">
    <div
      class="dropzone-container cc-dropzone dz-signup"
      data-cy="drag-and-drop"
      [class.error-state]="(companyInfoForm.submitted && !dzProperties.uploaded) || isDzError()"
      [ngStyle]="getUploadingDzStyles()"
      (click)="trackLogoUpload()"
    >
      <div class="p-3 m-5 dz-message dz-placeholder" id="dz-expanded-area" *ngIf="dragStart">
        <span class="cc-icon cc-icon-upload"></span>
        <div class="sm-mt-2 sm-text-secondary-text" i18n id="drop-logo-text">Drop your logo here</div>
      </div>
      <div class="dz-message" *ngIf="(!dragStart && isDzError()) || (!dragStart && !dzProperties.uploaded)">
        <span readonly class="px-3 py-2 btn btn-primary btn-sm" id="upload-logo-button" i18n
          >Upload your logo</span
        >
        <div class="sm-mt-4 sm-text-secondary-text" i18n>Or drag it into this box</div>
      </div>
    </div>
    <button
      class="button-regular button-blue align-self-center sm-mt-2"
      id="go-back-button"
      *ngIf="dragStart"
      i18n
      (click)="minimizeDz()"
      type="button"
    >
      <i class="mr-2 align-baseline fa fa-arrow-left"></i>Go Back
    </button>
    <div
      id="dz-error-required"
      class="mt-2 align-self-start sm-text-xs sm-text-default-red"
      i18n
      *ngIf="companyInfoForm.submitted && !dzProperties.uploaded && !isDzError()"
    >
      Required
    </div>
    <div class="mt-2 mb-1 d-flex align-self-end">
      <div
        class="sm-text-xs sm-text-default-red"
        i18n
        id="dz-error-message"
        *ngIf="isDzError() && !dzProperties.uploaded && !dragStart"
      >
        {{ dzProperties.error.message }}
      </div>
      <div *ngIf="!dzProperties.uploaded && !dragStart">
        <ng-template #tipContentRequirements i18n>
          <span class="sm-text-default sm-font-semibold">
            Logo must be:
            <ul class="sm-mt-1 sm-list-disc sm-pl-4 sm-text-sm sm-font-normal">
              <li>Less than 5MB</li>
              <li>Greater than 100x100 pixels</li>
              <li>Either a JPG, JPEG, PNG, or BMP file</li>
            </ul>
          </span>
        </ng-template>
        <button
          id="requirements-tooltip"
          type="button"
          class="px-2 py-1 btn btn-sm cc-btn-subtle-gray help-text"
          placement="bottom-end"
          tooltipClass="tip-container"
          [ngbTooltip]="tipContentRequirements"
          triggers="hover click"
        >
          <i class="mr-1 cc-icon cc-icon-question"></i><span i18n>View requirements</span>
        </button>
      </div>
    </div>
    <button
      (click)="uploadNewLogo()"
      id="upload-different-logo-button"
      *ngIf="dzProperties.uploaded && !dragStart"
      class="px-3 py-2 mt-2 btn btn-primary btn-sm"
      i18n
      type="button"
    >
      Upload a different logo
    </button>
  </div>
  <!-- Color Picker -->
  <div *ngIf="!dragStart">
    <div class="form-group d-flex align-items-center">
      <input
        readonly
        [cpPosition]="'bottom'"
        [cpPositionOffset]="'50%'"
        [cpPositionRelativeToArrow]="true"
        [(colorPicker)]="model.colorCode"
        [style.background-color]="model.colorCode"
        class="color-picker"
        name="colorCode"
      />
      <span for="colorCode" class="sm-ml-2" i18n>Pick your brand colour</span>
    </div>
    <div class="sm-flex sm-flex-col sm-items-center sm-gap-2">
      <ng-container *ngIf="isTrialGuideUpload; else signUp">
        <button
          type="submit"
          class="button-regular btn-block button-green next-button"
          [ccBusyButton]="isProcessing"
          [class.sm-opacity-60]="!companyInfoForm.valid || !isLogoUploadedOrError"
          [disabled]="!isLogoUploadedOrError"
          i18n
        >
          Save Changes
        </button>
        <button type="button" class="button-regular button-grey-2" (click)="skipStep()" i18n>Cancel</button>
      </ng-container>
      <ng-template #signUp>
        <button
          type="submit"
          class="btn btn-submit btn-primary btn-block next-button"
          ccSegmentCTA
          ctaContext="Sign Up Finish Setup"
          [ccBusyButton]="isProcessing"
          [class.sm-opacity-60]="!companyInfoForm.valid || !isLogoUploadedOrError"
          [disabled]="!companyInfoForm.valid || !isLogoUploadedOrError"
          i18n
        >
          Next
        </button>
        <button
          type="button"
          class="button-regular button-grey-2"
          (click)="skipStep()"
          i18n
          ccSegmentCTA
          ctaContext="Sign Up Finish Setup"
        >
          Skip this step
        </button>
      </ng-template>
    </div>
  </div>
</form>
