/* eslint-disable promise/avoid-new */
export function loadYouTubeAPI(): Promise<void> {
  return new Promise((resolve, reject) => {
    const doc = document;
    const scriptType = 'script';
    const scripts = doc.getElementsByTagName(scriptType);
    const hasYtFrameAPIScript = Array.from(scripts).some((script) =>
      script.src.includes('youtube.com/iframe_api'),
    );

    if (hasYtFrameAPIScript) {
      resolve();
      return;
    }

    const ytFrameAPIScript = doc.createElement(scriptType);
    ytFrameAPIScript.async = true;
    ytFrameAPIScript.src = 'https://www.youtube.com/iframe_api';

    ytFrameAPIScript.onload = () => {
      resolve();
    };

    ytFrameAPIScript.onerror = (error) => {
      reject(error);
    };

    const firstScript = doc.getElementsByTagName(scriptType)[0];
    firstScript.parentNode.insertBefore(ytFrameAPIScript, firstScript);
  });
}
