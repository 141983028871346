import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'app/shared/service/toastr.service';

import { NoWorkspaceFound } from './no-workspace-found.component';

@Injectable({
  providedIn: 'root',
})
export class NoWorkspaceFoundService {
  constructor(
    private readonly modal: NgbModal,
    private readonly toastService: ToastrService,
  ) {}

  openModal() {
    this.toastService.clear();

    this.modal.open(NoWorkspaceFound, {
      windowClass: 'full-modal-gray no-workspace-available-modal',
      backdrop: 'static',
    });
  }
}
