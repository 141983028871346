import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import Survicate from '@survicate/survicate-web-package/survicate_widget';
import { Subject, takeUntil } from 'rxjs';

import { IUser } from '@site-mate/dashpivot-shared-library';

import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

import { ErrorHandler } from './error-handler.service';

interface VisitorAttributes {
  [key: string]: string | number | boolean | Date;
}

interface SurvicateUserTraits extends VisitorAttributes {
  user_id: string;
  email: string;
  first_name: string;
  last_name: string;
}

@Injectable({ providedIn: 'root' })
export class SurvicateService {
  private readonly SVC_BOX_CLASS = 'non-clickable-svc-box';
  private readonly OVERLAY_CLASS = 'non-clickable-overlay';
  private readonly config = { workspaceKey: environment.survicate.key };

  private currentUser: IUser;
  private userTraits = {} as SurvicateUserTraits;
  private unSubscribe = new Subject<void>();

  survicateInstance = Survicate;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly userService: UserService,
    private readonly errorHandler: ErrorHandler,
  ) {}

  async initialize(): Promise<void> {
    try {
      await this.survicateInstance.init(this.config);

      this.setCurrentUserAndTraits();

      this.subscribeToEvents();
    } catch (error) {
      this.errorHandler.handleForDebug(error, { message: 'Error initializing Survicate' });
    }
  }

  addUserTraits(traits: VisitorAttributes): void {
    if (!this.survicateInstance) {
      return;
    }

    this.survicateInstance.setVisitorTraits(traits);
  }

  private setCurrentUserAndTraits() {
    this.userService.currentUser.pipe(takeUntil(this.unSubscribe)).subscribe((user) => {
      if (!user?._id) {
        return;
      }

      this.currentUser = user;
      this.userTraits = {
        user_id: this.currentUser._id,
        email: this.currentUser.email,
        first_name: this.currentUser.firstName,
        last_name: this.currentUser.lastName,
      };

      if (this.survicateInstance && this.survicateInstance.setVisitorTraits) {
        this.survicateInstance.setVisitorTraits(this.userTraits);
        this.unSubscribe.next();
        this.unSubscribe.complete();
      }
    });
  }

  private subscribeToEvents(): void {
    if (!this.survicateInstance) {
      return;
    }

    this.survicateInstance.addEventListener(Survicate.ApiEvent.surveyDisplayed, () => {
      this.setOverlayNonClickable();
    });

    this.survicateInstance.addEventListener(Survicate.ApiEvent.surveyClosed, () => {
      this.removeOverlayNonClickable();
    });

    this.survicateInstance.addEventListener(Survicate.ApiEvent.surveyCompleted, () => {
      this.removeOverlayNonClickable();
    });
  }

  private setOverlayNonClickable(): void {
    const survicateBox = this.document.querySelector('#survicate-box');
    const darkOverlay = survicateBox.querySelector('.sv__overlay--dark');
    const lightOverlay = survicateBox.querySelector('.sv__overlay--light');

    if (darkOverlay || lightOverlay) {
      survicateBox?.classList.add(this.SVC_BOX_CLASS);
    }

    if (darkOverlay) {
      darkOverlay.classList.add(this.OVERLAY_CLASS);
      darkOverlay.addEventListener('click', (e) => e.preventDefault());
    }

    if (lightOverlay) {
      lightOverlay.classList.add(this.OVERLAY_CLASS);
      lightOverlay.addEventListener('click', (e) => e.preventDefault());
    }
  }

  private removeOverlayNonClickable(): void {
    const survicateBox = this.document.querySelector('#survicate-box');
    survicateBox?.classList.remove(this.SVC_BOX_CLASS);
  }
}
