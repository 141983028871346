import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { intersection } from 'lodash-es';
import { ActiveToast } from 'ngx-toastr';
import { BehaviorSubject, Observable, firstValueFrom, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';

import { FolderKinds, ItemStates } from '@site-mate/dashpivot-shared-library';

import { FolderNavBarService } from 'app/layout/folder-navbar/folder-navbar.service';
import { ProjectWeb } from 'app/shared/model/project.model';
import { SessionStorageService } from 'app/shared/service/session-storage.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { IUserControllerMap, UserService } from 'app/user/user.service';

import { NavigateOptions } from './navigation.service.types';
import { TeamWeb } from '../shared/model/team.model';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private activeFolderSubject: BehaviorSubject<any> = new BehaviorSubject({});
  public activeFolder: Observable<any> = this.activeFolderSubject.asObservable();
  private archiveToast: ActiveToast<any>;

  constructor(
    private readonly router: Router,
    private readonly teamService: TeamService,
    private readonly toastrService: ToastrService,
    private readonly userService: UserService,
    private readonly folderNavbarService: FolderNavBarService,
    private readonly sessionStorageService: SessionStorageService,
  ) {
    this.teamService.currentTeam.subscribe((folder) => {
      this.activeFolderSubject.next(folder || {});
    });
  }

  async navigateToFirstAccessibleFolderInCompany(companyId: string) {
    const userControllerMap = this.userService.getUserControllerMap();
    const folderTree = await this.folderNavbarService.getCompanyTree(companyId).pipe(take(1)).toPromise();
    const archivedFolderTree = await this.folderNavbarService
      .getCompanyTree(companyId, ItemStates.Archived)
      .pipe(take(1))
      .toPromise();

    const canUserNavigateToOrganisation = await this.tryNavigatingToOrganisation(
      companyId,
      userControllerMap,
    );
    if (canUserNavigateToOrganisation) {
      return;
    }

    const canUserNavigateToProject =
      (await this.tryNavigatingToProject(userControllerMap, folderTree)) ||
      (await this.tryNavigatingToProject(userControllerMap, archivedFolderTree));
    if (canUserNavigateToProject) {
      return;
    }

    const canUserNavigateToTeam =
      (await this.tryNavigatingToTeam(folderTree)) || (await this.tryNavigatingToTeam(archivedFolderTree));
    if (canUserNavigateToTeam) {
      return;
    }

    this.toastrService.errorByKey('noFolderToNavigate');
    throw new Error('No folder to navigate to');
  }

  public async getFirstAccessibleFolderInCompany(companyId: string): Promise<UrlTree> {
    const userControllerMap = this.userService.getUserControllerMap();

    const [folderTree, archivedFolderTree] = await this.getCompanyFolderTree(companyId);

    const accessibleFolderUrlTree =
      this.getAccessibleFolderUrlTree(companyId, userControllerMap, folderTree) ||
      this.getAccessibleFolderUrlTree(companyId, userControllerMap, archivedFolderTree);

    if (accessibleFolderUrlTree) {
      return accessibleFolderUrlTree;
    }

    this.toastrService.errorByKey('noFolderToNavigate');
    throw new Error('No folder to navigate to');
  }

  private async getCompanyFolderTree(companyId: string) {
    return firstValueFrom(
      forkJoin([
        this.folderNavbarService.getCompanyTree(companyId).pipe(take(1)),
        this.folderNavbarService.getCompanyTree(companyId, ItemStates.Archived).pipe(take(1)),
      ]),
    );
  }

  private getAccessibleFolderUrlTree(
    companyId: string,
    userControllerMap: IUserControllerMap,
    folderTree: ProjectWeb[],
  ): UrlTree | null {
    if (this.checkUserHasAccessToOrganisation(companyId, userControllerMap)) {
      return this.router.createUrlTree(['/', 'home', 'companies', companyId]);
    }

    const firstAccessibleProject = this.firstAccessibleProjectFolder(userControllerMap, folderTree);
    if (firstAccessibleProject) {
      return this.router.createUrlTree(['/', 'home', 'projects', firstAccessibleProject._id]);
    }

    const firstAccessibleTeam = this.firstAccessibleTeam(folderTree);
    if (firstAccessibleTeam) {
      return this.router.createUrlTree(['/', 'home', 'teams', firstAccessibleTeam]);
    }
    return null;
  }

  private async tryNavigatingToOrganisation(companyId: string, userControllerMap: IUserControllerMap) {
    if (this.checkUserHasAccessToOrganisation(companyId, userControllerMap)) {
      await this.navigateToOrganisation(companyId);
      return true;
    }

    return false;
  }

  private checkUserHasAccessToOrganisation(companyId: string, userControllerMap: IUserControllerMap) {
    const isCompanyMember = this.userService.isCompanyMemberOf(companyId);
    return userControllerMap.isAdmin || userControllerMap.companies?.[companyId] || isCompanyMember;
  }

  async navigateToOrganisation(orgId: string, options: NavigateOptions = {}) {
    const userControllerMap = this.userService.getUserControllerMap();
    const isCompanyMember = this.userService.isCompanyMemberOf(orgId);
    const userAuthorised = userControllerMap.isAdmin || userControllerMap.companies[orgId] || isCompanyMember;
    if (!userAuthorised) {
      this.toastrService.errorByKey('noFolderAccess');
      return;
    }

    if (!options.forceReload && this.activeFolderSubject.getValue()._id === orgId) {
      return;
    }
    this.teamService.setNavigatedFolder(orgId, FolderKinds.Company);
    await this.router.navigate(['/', 'home', 'companies', orgId]);
  }

  private async tryNavigatingToProject(userControllerMap: IUserControllerMap, folderTree: ProjectWeb[]) {
    const firstAccessibleProjectFolder = this.firstAccessibleProjectFolder(userControllerMap, folderTree);
    if (firstAccessibleProjectFolder) {
      await this.navigateToProject(firstAccessibleProjectFolder._id);
      return true;
    }

    return false;
  }

  private firstAccessibleProjectFolder(userControllerMap: IUserControllerMap, folderTree: ProjectWeb[]) {
    return folderTree.find((project) => userControllerMap.projects?.[project._id]);
  }

  async navigateToProject(projectId: string, options: NavigateOptions = {}) {
    const activeFolder = this.activeFolderSubject.getValue();
    const userControllerMap = this.userService.getUserControllerMap();
    const companyId = activeFolder.isCompany ? activeFolder._id : activeFolder.companyId;
    const isCompanyMember = this.userService.isCompanyMemberOf(companyId);
    const isProjectMember = this.userService.isProjectMemberOf(projectId);
    const userAuthorised =
      userControllerMap.isAdmin ||
      userControllerMap.projects[projectId] ||
      isCompanyMember ||
      isProjectMember;

    if (!userAuthorised) {
      this.toastrService.errorByKey('noFolderAccess');
      return;
    }
    if (!options.forceReload && this.activeFolderSubject.getValue()._id === projectId) {
      return;
    }

    this.teamService.setNavigatedFolder(projectId, FolderKinds.Project);
    await this.router.navigate(['/', 'home', 'projects', projectId]);
  }

  private async tryNavigatingToTeam(folderTree: ProjectWeb[]) {
    const firstAccessibleTeam = this.firstAccessibleTeam(folderTree);
    if (firstAccessibleTeam) {
      await this.navigateToTeam(firstAccessibleTeam);
      return true;
    }
    return false;
  }

  private firstAccessibleTeam(folderTree: ProjectWeb[]) {
    const allTeamIdsFromCompanyTree: string[] = folderTree.reduce(
      (acc, project) => acc.concat(project.teams.map((team) => team._id)),
      [],
    );
    const userTeams = this.userService.getCurrentUser().standardUserOf.teams;
    return intersection(allTeamIdsFromCompanyTree, userTeams)?.[0];
  }

  async navigateToTeam(teamId: string, options: NavigateOptions = {}) {
    if (!options.forceReload && this.activeFolderSubject.getValue()._id === teamId) {
      return;
    }

    this.teamService.setNavigatedFolder(teamId, FolderKinds.Team);
    await this.router.navigate(['/', 'home', 'teams', teamId]);
  }

  async navigateToParentFolder() {
    const activeFolder = this.activeFolderSubject.getValue();
    if (activeFolder.isTeam) {
      await this.navigateToProject(activeFolder.projectId);
    }
    if (activeFolder.isProject) {
      await this.navigateToOrganisation(activeFolder.companyId);
    }
  }

  displayArchiveToastIfNeeded(folder: ProjectWeb | TeamWeb) {
    const disableToastKey = 'disableArchiveToast';
    const toastDisabled = this.sessionStorageService.getItem(disableToastKey);

    if (folder.state === ItemStates.Archived && !toastDisabled) {
      this.archiveToast = this.toastrService.warnByKey('archiveFolderToast', {
        disableTimeOut: true,
        tapToDismiss: false,
      });
    }

    if (folder.state === ItemStates.Active || folder.isCompany) {
      if (this.archiveToast) {
        this.toastrService.clear(this.archiveToast.toastId);
      }
      this.sessionStorageService.removeItem(disableToastKey);
    }

    if (this.archiveToast) {
      this.archiveToast.onHidden.pipe(take(1)).subscribe(() => {
        const activeFolder = this.activeFolderSubject.getValue();
        if (activeFolder.state === ItemStates.Archived) {
          this.sessionStorageService.setItem(disableToastKey, 'true');
        }
      });
    }
  }
}
