import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { environment } from 'environments/environment';

import { loadYouTubeAPI } from './youtube-video-player-api-loader';

@Component({
  selector: 'cc-youtube-video-player',
  templateUrl: './youtube-video-player.component.html',
})
export class YoutubeVideoPlayerComponent {
  /* Iframe param originForSecurityReasons */
  readonly origin = environment.uiUrl;

  @Input({ required: true }) videoId: string;
  @Output() onStateChange: EventEmitter<Event> = new EventEmitter();

  constructor(private readonly errorHandler: ErrorHandler) {}

  ngOnInit(): void {
    loadYouTubeAPI().catch((error) => {
      this.errorHandler.handleForDebug('Failed to load YouTube API', error);
    });
  }
}
