/* eslint-disable max-lines */
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbAccordion, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { EventTypes, SignUpSteps } from '@site-mate/dashpivot-shared-library';

import { IBusinessAttribute } from 'app/business-attributes/business-attribute.model';
import { BusinessAttributesService } from 'app/business-attributes/business-attributes.service';
import { ICompanyHomeSettings } from 'app/home/company-home-settings.model';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { OrgService } from 'app/org/org.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { TeamService } from 'app/shared/service/team.service';

import { SelectorTypes } from './selector-types.enum';
import { SignUpService } from '../sign-up-simplified/sign-up.service';

@Component({
  selector: 'cc-business-attributes-selector',
  templateUrl: './business-attributes-selector.component.html',
  styleUrls: ['./business-attributes-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BusinessAttributesSelectorComponent implements OnInit {
  @Input() isLoading = false;

  @ViewChild('accordion') accordionElement: NgbAccordion;
  @ViewChild('verticalTypeahead') verticalTypeahead: ElementRef;
  @ViewChild('roleTypeahead') roleTypeahead: ElementRef;

  readonly otherOption = {
    code: 'other',
    name: 'Other',
  } as IBusinessAttribute;

  heading: string;
  subtitle: string;
  errorMessage: string;

  selectorTypes = SelectorTypes;
  disableTypeahead: boolean;

  roleOptions: IBusinessAttribute[];
  verticalOptions: IBusinessAttribute[];

  selectedVertical: IBusinessAttribute;
  selectedRole: IBusinessAttribute;

  otherVerticalSelected: boolean;
  otherRoleSelected: boolean;

  finishButtonClicked = false;
  isSubmitted = false;

  rejectStringsWithOnlyWhiteSpaces = /^(\s+\S+\s*)*(?!\s).*$/;

  selectorForm = new UntypedFormGroup({
    vertical: new UntypedFormGroup({
      standardOption: new UntypedFormControl('', Validators.required),
      otherOption: new UntypedFormControl(''),
    }),
    role: new UntypedFormGroup({
      standardOption: new UntypedFormControl('', Validators.required),
      otherOption: new UntypedFormControl(''),
    }),
  });

  private unsubscribe = new Subject<void>();
  private readonly texts = {
    fastTrackYourSetup: 'fastTrackYourSetup',
    tellUsALittleBitAboutYourSelf: 'tellUsALittleBitAboutYourSelf',
  };

  accordionPanels = [
    {
      header: 'Step 1 - Which industry best describes the work your company does?',
      type: SelectorTypes.Vertical,
    },
    {
      header: 'Step 2 - Which role does your company perform or specialise in?',
      type: SelectorTypes.Role,
    },
  ];

  isFromWorkspaceCreation = false;

  constructor(
    private readonly i18nService: TmpI18NService,
    private readonly businessAttributesService: BusinessAttributesService,
    private readonly errorHandler: ErrorHandler,
    private readonly signupService: SignUpService,
    protected readonly ref: ChangeDetectorRef,
    private readonly orgService: OrgService,
    private readonly teamService: TeamService,
    private readonly activeModal: NgbActiveModal,
    private readonly eventsService: EventsService,
  ) {}

  ngOnInit(): void {
    const { fastTrackYourSetup, tellUsALittleBitAboutYourSelf } = this.texts;

    this.heading = this.i18nService.getMessage(fastTrackYourSetup);
    this.subtitle = this.i18nService.getMessage(tellUsALittleBitAboutYourSelf);

    this.loadVerticals();
    this.loadRoles();
  }

  loadVerticals(): void {
    this.businessAttributesService
      .getVerticals()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (verticals) => {
          if (!verticals.length) {
            this.signupService.setActiveStep(SignUpSteps.MobileNumber);
          }

          this.verticalOptions = verticals
            ?.map((vertical) => {
              vertical.name = vertical.description;
              return vertical;
            })
            .sort((a, b) => a.code.localeCompare(b.code));

          this.verticalOptions.push(this.otherOption);
        },
        (error) => {
          this.signupService.setActiveStep(SignUpSteps.MobileNumber);
          this.eventsService.trackEvent(EventTypes.SignupFormError, { Context: 'Vertical Role Retrieval' });
          this.errorHandler.handle(error);
        },
      );
  }

  loadRoles(): void {
    this.businessAttributesService
      .getRoles()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (roles) => {
          if (!roles.length) {
            this.signupService.setActiveStep(SignUpSteps.MobileNumber);
            return;
          }

          this.roleOptions = roles.sort((a, b) => a.code.localeCompare(b.code));
          this.roleOptions.push(this.otherOption);
        },
        (error) => {
          this.signupService.setActiveStep(SignUpSteps.MobileNumber);
          this.eventsService.trackEvent(EventTypes.SignupFormError, { Context: 'Vertical Role Retrieval' });
          this.errorHandler.handle(error);
        },
      );
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private isSubmissionValid(): boolean {
    return !!this.selectedVertical && !!this.selectedRole && this.selectorForm.valid;
  }

  submitForm(): void {
    this.finishButtonClicked = true;
    this.selectorForm.markAllAsTouched();
    if (this.isSubmissionValid() && !this.isFromWorkspaceCreation) {
      this.isSubmitted = true;
      this.eventsService.trackEvent(EventTypes.CtaClicked, { Context: 'Vertical and Role modal' });

      this.signupService.updateSignupModel({
        vertical: this.selectedVertical,
        role: this.selectedRole,
      });
      this.signupService.setActiveStep(SignUpSteps.MobileNumber);
    }

    if (this.isSubmissionValid() && this.isFromWorkspaceCreation) {
      this.updateCompanyBusinessAttribute(this.selectedVertical._id, this.selectedRole._id);
    }
  }

  updateCompanyBusinessAttribute(verticalId: string, roleId: string) {
    const { companyMetadata } = this.teamService.getCurrentTeam();
    const { _id: id } = companyMetadata;

    const companyUpdate: Partial<ICompanyHomeSettings> = {
      id,
      businessAttributes: [verticalId, roleId],
    };
    this.isLoading = true;
    this.orgService
      .updateCompany(companyUpdate)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        () => {
          this.activeModal.close();
        },
        (error) => {
          this.isLoading = true;
          error.toastKey = 'workspaceSettingsUpdateFailed';
          this.errorHandler.handle(error);
        },
      );
  }

  selectorClick(panel: SelectorTypes, optionCode: string): void {
    const selectorPanels = [
      {
        condition: (panelType: SelectorTypes) => panelType === SelectorTypes.Vertical,
        action: this.actionOnVerticalClick.bind(this, optionCode),
      },
      {
        condition: (panelType: SelectorTypes) => panelType === SelectorTypes.Role,
        action: this.actionOnRoleClick.bind(this, optionCode),
      },
    ];

    const selectorPanelActions = selectorPanels.find((entry) => entry.condition(panel));
    selectorPanelActions?.action();
  }

  onVerticalTypeaheadClick(code: string): void {
    const selectedVertical = this.verticalOptions.find(({ code: verticalCode }) => verticalCode === code);

    this.selectorForm.get('vertical.standardOption').patchValue(selectedVertical);
    this.actionOnVerticalClick(code);
  }

  onRoleTypeaheadClick(code: string): void {
    const selectedRole = this.roleOptions.find(({ code: roleCode }) => roleCode === code);

    this.selectorForm.get('role.standardOption').patchValue(selectedRole);
    this.actionOnRoleClick(code);
  }

  actionOnVerticalClick(optionCode: string): void {
    this.disableTypeahead = false;

    if (optionCode === this.otherOption.code) {
      delete this.selectedVertical;
      this.otherVerticalSelected = true;
      this.selectorForm.get('vertical.otherOption').patchValue('');

      this.ref.detectChanges();
      this.scrollIntoVerticalTypeaheadView();
      return;
    }

    this.selectedVertical = this.verticalOptions.find(({ code }) => code === optionCode);
    this.otherVerticalSelected = false;

    this.eventsService.trackEvent(EventTypes.SignupVerticalSelected, { Context: optionCode });
    this.ref.detectChanges();
    this.accordionElement.expand('panel-1');
  }

  actionOnRoleClick(optionCode: string): void {
    this.resetValidatorsOnOtherOptionRole();
    if (optionCode === this.otherOption.code) {
      delete this.selectedRole;
      this.otherRoleSelected = true;
      this.makeRoleOtherOptionRequired();
      this.selectorForm.get('role.otherOption').patchValue('');

      this.ref.detectChanges();
      this.scrollIntoRoleTypeaheadView();

      return;
    }

    this.selectedRole = this.roleOptions.find(({ code }) => code === optionCode);
    this.otherRoleSelected = false;

    this.eventsService.trackEvent(EventTypes.SignupRoleSelected, { Context: optionCode });
    this.ref.detectChanges();
    this.accordionElement.collapseAll();
  }

  scrollIntoVerticalTypeaheadView() {
    this.verticalTypeahead.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  scrollIntoRoleTypeaheadView() {
    this.roleTypeahead.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  private makeRoleOtherOptionRequired(): void {
    const validators = [Validators.required, Validators.pattern(this.rejectStringsWithOnlyWhiteSpaces)];
    this.selectorForm.get('role.otherOption').setValidators(validators);
    this.selectorForm.get('role.otherOption').updateValueAndValidity();
  }

  private resetValidatorsOnOtherOptionRole(): void {
    this.selectorForm.get('role.otherOption').clearValidators();
    this.selectorForm.get('role.otherOption').updateValueAndValidity();
  }

  submitCustomVerticalValue(): void {
    const verticalOtherOption = this.selectorForm.get('vertical.otherOption');

    const customVerticalValue = verticalOtherOption.value.trim();
    if (!customVerticalValue) {
      return;
    }

    const context = { Context: customVerticalValue };
    this.eventsService.trackEvent(EventTypes.SignupVerticalManuallyEntered, context);

    this.selectedVertical = this.otherOption;
    this.disableTypeahead = true;

    this.ref.detectChanges();
    this.accordionElement.expand('panel-1');
  }

  submitCustomRoleValue(): void {
    const roleOtherOption = this.selectorForm.get('role.otherOption');
    const customRoleValue = roleOtherOption.value.trim();

    if (!customRoleValue) {
      return;
    }

    const context = { Context: customRoleValue };
    this.eventsService.trackEvent(EventTypes.SignupRoleManuallyEntered, context);

    this.selectedRole = this.otherOption;
    this.accordionElement.collapseAll();
  }
}
