import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { HttpClientService } from '../../shared/service/http-client.service';

@Injectable({
  providedIn: 'root',
})
export class ActivityService {
  onActivitiesRefreshRequired = new Subject<void>();

  constructor(private http: HttpClientService) {}

  getTeamUserActivities(teamId, limit, offset) {
    return this.http.get(`teams/${teamId}/user-activities?limit=${limit}&offset=${offset}`);
  }

  getProjectUserActivities(projectId, limit, offset) {
    return this.http.get(`projects/${projectId}/user-activities?limit=${limit}&offset=${offset}`);
  }

  getCompanyUserActivities(companyId, limit, offset) {
    return this.http.get(`companies/${companyId}/user-activities?limit=${limit}&offset=${offset}`);
  }
}
