<form class="form animate-fade-in" [formGroup]="userSignatureForm" (ngSubmit)="onFinalSubmit()">
  <div>
    <div class="sm-mb-4 sm-text-center">
      <h1 class="heading-text">Add your signature</h1>
      <p class="sm-text-center sm-text-default-text" i18n>
        You'll use your signature to sign-off and approve forms in Dashpivot. Don’t worry, you can change your
        signature later in your profile.
      </p>
    </div>

    <div class="form-group sm-my-2">
      <cc-digital-font-signature
        [userInfo]="model"
        [options]="signatureCanvasOptions"
        [form]="userSignatureForm"
      ></cc-digital-font-signature>
    </div>

    <button
      type="submit"
      class="py-3 btn btn-submit btn-primary btn-block"
      ccSegmentCTA
      ctaContext="Sign up Page Signature"
      [ccBusyButton]="isProcessing"
      [class.sm-opacity-60]="userSignatureForm.invalid"
      [class.disabled]="userSignatureForm.invalid"
      [disabled]="userSignatureForm.invalid"
    >
      <span i18n>Next</span>
    </button>
  </div>
</form>
