/* eslint-disable max-lines */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import confetti from 'canvas-confetti';
import moment from 'moment-timezone';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { BehaviorSubject, Observable, catchError, lastValueFrom, throwError } from 'rxjs';

import { DomainUtils, EventTypes, SignUpSteps, SitemateRegions } from '@site-mate/dashpivot-shared-library';

import { AuthService } from 'app/auth/auth.service';
import { GlobalApiService } from 'app/global-api/global-api.service';
import { SegmentService } from 'app/segment/segment.service';
import { WINDOW } from 'app/shared/factory/window.factory';
import { IPasswordValidation } from 'app/shared/model/password-validation.model';
import { SignUp } from 'app/shared/model/sign-up.model';
import { CompanyService } from 'app/shared/service/company.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { SurvicateService } from 'app/shared/service/survicate.service';
import Countries from 'app/user/shared/countries.json';
import MappedTimeZones from 'app/user/shared/mappedTimezones.json';
import { environment } from 'environments/environment';

import { SignupErrorMessages } from './model/sign-up-error-messages';
import { SignUpPageVisitedEventContext } from './model/sign-up-page-visited-event-context.enum';
import { SignupResponse } from './model/signup-response.model';
import { UserService } from '../user.service';

export type ValidateAccountSubdomainResponse = {
  subdomainExists?: boolean;
  isValidSubdomain?: boolean;
  message?: 'Subdomain is already taken';
};
export type SubdomainDerivedFrom = 'email' | 'companyName';

@Injectable({
  providedIn: 'root',
})
export class SignUpService {
  readonly sitemateColor = '#2E81DA';

  private activeStepSubject = new BehaviorSubject<SignUpSteps>(SignUpSteps.UserInfo);
  activeStep$ = this.activeStepSubject.asObservable();

  private queryParamSubject = new BehaviorSubject<Params>({});
  queryParams$ = this.queryParamSubject.asObservable();

  private signUpResponseSubject = new BehaviorSubject<{ teamId: string; templateId: string }>({
    teamId: '',
    templateId: '',
  });
  signUpResponse$ = this.queryParamSubject.asObservable();

  private signUpModel = new BehaviorSubject<SignUp>({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    mobile: '',
    companyName: '',
    colorCode: this.sitemateColor,
    isReCaptchaV3: false,
    vertical: [],
    role: [],
    reCaptchaResponse: '',
    reCaptchaSubmission: {
      v2: false,
      v3: false,
    },
    isSSOSignUp: false,
    continent: '',
    subdomain: '',
    region: SitemateRegions[environment.region],
  });
  signupModel$ = this.signUpModel.asObservable();

  private isProcessingSubject = new BehaviorSubject<boolean>(false);
  isProcessing$ = this.isProcessingSubject.asObservable();

  shouldShowSignUpUserInfoFields = false;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(WINDOW) private readonly window: Window,
    private readonly userService: UserService,
    private readonly eventsService: EventsService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly errorHandler: ErrorHandler,
    private readonly globalApi: GlobalApiService,
    private readonly companyService: CompanyService,
    private readonly survicateService: SurvicateService,
    private readonly segmentService: SegmentService,
  ) {}

  async onSubmit() {
    try {
      this.isProcessingSubject.next(true);
      await this.reCaptchaV3Verification();

      const anonymousId = this.eventsService.getAnonymousId();

      const queryParams = this.getQueryParams();
      this.updateSignupModel({
        ...queryParams,
        isReCaptchaV3: !this.getSignUpModel().reCaptchaSubmission.v2,
        referrer: this.document.referrer,
        eventAnonymousId: anonymousId,
      });

      const signUpResponse = await this.submitSignUpModel();
      this.survicateService.addUserTraits({
        signUpReference: queryParams.reference,
        signUpTemplateId: signUpResponse.signUpTemplateId,
      });

      await this.triggerPostSignUpActions(signUpResponse);
      this.eventsService.alias(signUpResponse.loginData?.userId);
    } catch (error) {
      this.signupErrorHandling(error);
    } finally {
      this.isProcessingSubject.next(false);
    }
  }

  private redirectToHome(subdomain?: string) {
    if (environment.featureToggles.subdomainRedirectionAfterSignup) {
      const currentURL = this.window.location.href;

      // Extract the root domain and path from the current URL
      const urlParts = currentURL.split('://');
      const domainAndPath = urlParts[1].split('/');
      const rootDomain = domainAndPath[0];

      // Create the new URL with the subdomain
      const newURL = `https://${subdomain}.${rootDomain}/home`;

      // Redirect to the new URL
      this.window.location.href = newURL;
    } else {
      void this.router.navigate(['/', 'home']);
    }
  }

  private async triggerPostSignUpActions(signUpResponse: SignupResponse) {
    await this.authService.storeAuthTokens(signUpResponse.loginData);

    if (!this.segmentService.isSegmentReadyStatus()) {
      this.userService.sendAdBlockStatus({ status: true, blockedContext: 'analytics' }).subscribe();
    }

    this.eventsService.trackEvent(EventTypes.SignupFormSubmitted);
    this.redirectToHome(signUpResponse.subdomain);
  }

  private async submitSignUpModel(): Promise<SignupResponse> {
    const signUpResponse = await lastValueFrom(this.userService.signUp(this.getSignUpModel()));
    this.setSignUpResponse({
      templateId: signUpResponse.signUpTemplateId,
      teamId: signUpResponse.teamId,
    });
    return signUpResponse;
  }

  private async reCaptchaV3Verification() {
    if (!this.getSignUpModel().reCaptchaSubmission.v3) {
      this.updateSignupModel({
        reCaptchaResponse: await lastValueFrom(this.getRecaptchaToken()),
        reCaptchaSubmission: {
          v3: true,
          v2: false,
        },
      });
    }
  }

  getRecaptchaToken(): Observable<string> {
    const signupAction = 'signup';
    return this.reCaptchaV3Service.execute(signupAction).pipe(
      catchError((error) => {
        return throwError(() => error);
      }),
    );
  }

  private signupErrorHandling(error: any) {
    const errorMessage = error.error?.message;
    const isReCaptchaError =
      errorMessage === SignupErrorMessages.ReCaptchaThresholdNotMet ||
      errorMessage === SignupErrorMessages.ReCaptchaBrowserError;
    if (isReCaptchaError) {
      this.eventsService.trackEvent(EventTypes.SignUpHumanVerificationRequired);
      this.setActiveStep(SignUpSteps.HumanVerification);
      return;
    }

    this.eventsService.trackEvent(EventTypes.SignupFormError);
    this.navigateBackOnError(error);
  }

  private navigateBackOnError(error: Error) {
    this.errorHandler.handle(error, {
      email: this.signUpModel.value?.email,
      mobile: this.signUpModel.value?.mobile,
      source: 'self sign up',
    });

    const delayNavigationToShowError = 3000;
    setTimeout(() => {
      void this.router.navigate(['/']);
    }, delayNavigationToShowError);
  }

  submitSignUpPageVisitedAndViewedEvents(): void {
    const { templateId, reference } = this.getQueryParams();
    const isTemplateReferral = templateId;
    const isIFrameReferral = reference?.includes('iframe') as boolean;
    const isWatchDemoReferral = reference?.includes('demo-watcher-page') as boolean;
    const eventMetadata: { UserContinent: string; Context?: string } = {
      UserContinent: this.initialUserContinent,
    };

    if (isTemplateReferral && !isWatchDemoReferral) {
      eventMetadata.Context = isIFrameReferral
        ? SignUpPageVisitedEventContext.TemplateReferralIframe
        : SignUpPageVisitedEventContext.TemplateReferral;
    } else if (isWatchDemoReferral) {
      eventMetadata.Context = isTemplateReferral
        ? SignUpPageVisitedEventContext.WatchDemoTR
        : SignUpPageVisitedEventContext.WatchDemoNTR;
    }
    this.eventsService.trackEvent(EventTypes.SignUpPageVisited, eventMetadata);
    this.eventsService.trackPreSignUpAnonymousEvent(EventTypes.SignUpPageViewed, {
      context: eventMetadata.Context,
      userContinent: eventMetadata.UserContinent,
      proxyFromServer: true,
      templateId,
    });
  }

  private confettiPop(particleRatio: number, opts: any): void {
    const confettiCount = 200;
    const colors = ['#3EC5D6', '#F6BB2D', '#F99226', '#6575BD'];
    const modalZIndex = 1050;

    confetti({
      origin: { y: 0.35 },
      colors,
      particleCount: Math.floor(confettiCount * particleRatio),
      zIndex: modalZIndex + 1,
      ...opts,
    });
  }

  popConfetti(): void {
    this.confettiPop(1, {
      spread: 26,
      startVelocity: 55,
    });
    this.confettiPop(0.2, {
      spread: 60,
    });
    this.confettiPop(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });
    this.confettiPop(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });
    this.confettiPop(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  get isTemplateReferral(): boolean {
    const queryParams = this.getQueryParams();
    const isSitemateUseCaseReferral =
      queryParams?.reference?.includes('app') || queryParams?.reference?.includes('software');

    return queryParams.templateId && !isSitemateUseCaseReferral;
  }

  get isNonTemplateReferral(): boolean {
    const queryParams = this.getQueryParams();
    const isSitemateUseCaseReferral =
      queryParams?.reference?.includes('app') || queryParams?.reference?.includes('software');

    return !queryParams.templateId || !!isSitemateUseCaseReferral;
  }

  setQueryParams(params: Params) {
    this.queryParamSubject.next(params);
  }

  getQueryParams(): Params {
    return this.queryParamSubject.value;
  }

  setSignUpResponse(response: { teamId: string; templateId: string }) {
    this.signUpResponseSubject.next({ ...this.signUpResponseSubject.value, ...response });
  }

  getSignUpResponse() {
    return this.signUpResponseSubject.value;
  }

  updateSignupModel(info: object): void {
    this.signUpModel.next({ ...this.signUpModel.value, ...info });
  }

  getSignUpModel(): SignUp {
    return this.signUpModel.value;
  }

  setActiveStep(step: SignUpSteps): void {
    this.activeStepSubject.next(step);
  }

  getCurrentStep(): SignUpSteps {
    return this.activeStepSubject.value;
  }

  setProcessing(isProcessing: boolean): void {
    this.isProcessingSubject.next(isProcessing);
  }

  getSignUpUserInfoFieldsVisibility(): boolean {
    return this.shouldShowSignUpUserInfoFields;
  }

  setSignUpUserInfoFieldsVisibility(shouldShow: boolean): void {
    this.shouldShowSignUpUserInfoFields = shouldShow;
  }

  get userCountry(): string {
    const userTimeZone = (moment as any).tz.guess(true);
    return MappedTimeZones[userTimeZone]?.mcode;
  }

  get initialUserContinent(): string {
    return Countries.find((country) => country.value === this.userCountry)?.continentCode;
  }

  validatePassword(password: string): IPasswordValidation {
    const minLength = 10;
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= minLength;

    return {
      hasLowercase,
      hasUppercase,
      hasNumber,
      hasMinLength,
    };
  }

  getCombinedPasswordValidationRegex(): RegExp {
    return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/;
  }

  normalizeSubdomain(companyName: string) {
    return DomainUtils.normalizeSubdomain(companyName);
  }

  async validateAccountSubdomain(subdomain: string): Promise<ValidateAccountSubdomainResponse> {
    try {
      return await lastValueFrom(this.globalApi.validateSubdomain(subdomain));
    } catch (error) {
      // @ts-expect-error implicitly has an 'any' type
      if (error.status === 400) {
        // @ts-expect-error implicitly has an 'any' type
        return error.error as ValidateAccountSubdomainResponse;
      }

      this.errorHandler.handle(error);
      throw error;
    }
  }

  async deriveAccountSubdomain(email: string, companyName: string) {
    let derivedSubdomain = '';
    let subdomainDerivedFrom: SubdomainDerivedFrom;

    const deriveFromCompanyName = (name: string) => {
      const companyNameDomain = this.normalizeSubdomain(name);

      derivedSubdomain = companyNameDomain;
      subdomainDerivedFrom = 'companyName';
    };

    try {
      const emailDomain = email.split('@')[1].toLowerCase();

      if (this.isExemptedEmailDomain(emailDomain)) {
        deriveFromCompanyName(companyName);
        return { derivedSubdomain, subdomainDerivedFrom };
      }

      // Is a non-public domain if the request goes through
      await lastValueFrom(this.companyService.validatePublicDomains([emailDomain]));

      derivedSubdomain = this.normalizeSubdomain(emailDomain.split('.')[0]); // Remove TLD (e.g. .com, .co.uk);
      subdomainDerivedFrom = 'email';
    } catch (error) {
      // @ts-expect-error implicitly has an 'any' type
      if (error.status === 400) {
        // Is a public domain if server responds with status 400
        deriveFromCompanyName(companyName);
      } else {
        this.errorHandler.handle(error);
        throw error;
      }
    }

    return { derivedSubdomain, subdomainDerivedFrom };
  }

  private isExemptedEmailDomain(emailDomain: string): boolean {
    const EXEMPTED_EMAIL_DOMAINS = ['sitemate.com'];
    return EXEMPTED_EMAIL_DOMAINS.includes(emailDomain);
  }

  async handleAccountSubdomain(email: string, companyName: string) {
    const { derivedSubdomain, subdomainDerivedFrom } = await this.deriveAccountSubdomain(email, companyName);
    const { isValidSubdomain, subdomainExists } = await this.validateAccountSubdomain(derivedSubdomain);
    if (isValidSubdomain || subdomainExists) {
      // It might be weird to see here that we should proceed if the subdomain exists.
      // As per SMW-601, the server will keep on retrying and append the number of
      // retries to the subdomain until it finds a unique one (up to a certain point -- edge case).
      this.updateSignupModel({ subdomain: derivedSubdomain });
      return { success: true };
    }

    return {
      success: false,
      errorMessage: this.createHandleAccountSubdomainErrorMessage(subdomainDerivedFrom),
    };
  }

  private createHandleAccountSubdomainErrorMessage(subdomainDerivedFrom: SubdomainDerivedFrom): string {
    const subdomainDerivedFromLabel =
      subdomainDerivedFrom === 'email' ? 'email address domain' : 'company name';
    return `Your ${subdomainDerivedFromLabel} contains inappropriate or reserved terms. Please try using a different ${subdomainDerivedFromLabel}.`;
  }
}
