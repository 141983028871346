/* eslint-disable max-lines */
import { AfterViewInit, Component, HostListener, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import esmDropzone, { Dropzone } from 'dropzone/dist/dropzone';
import moment from 'moment-timezone';
import { catchError, tap, throwError } from 'rxjs';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { TeamWeb } from 'app/shared/model/team.model';
import { CompanyService } from 'app/shared/service/company.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { ImageManipulationService } from 'app/shared/service/image-manipulation/image-manipulation.service';
import { MetaDataService } from 'app/shared/service/meta-data.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'cc-sign-up-company-branding',
  templateUrl: './sign-up-company-branding.component.html',
  styleUrls: ['./sign-up-company-branding.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SignUpCompanyBrandingComponent implements AfterViewInit {
  readonly sitemateColor = '#2E81DA';
  readonly maxResolution = 2000;
  readonly minResolution = 100;
  readonly fallbackLogo =
    'https://ccapp-logos.s3-ap-southeast-2.amazonaws.com/597573fec449c722e2b087d4-5b7df490657c8010cb75d5b3.png';

  model = {
    colorCode: this.sitemateColor,
    companyLogo: '',
  };

  dragStart = false;
  isProcessing = false;
  isTrialGuideUpload = false;
  dzProperties = {
    uploading: false,
    uploaded: false,
    error: {
      isDimensionError: false,
      message: '',
    },
    lastUploadedFilePreview: '',
  };

  dropzone: esmDropzone.Dropzone;
  companyLogo: string;
  colorCode: string;

  constructor(
    private readonly errorHandler: ErrorHandler,
    public readonly activeModal: NgbActiveModal,
    private readonly metadataService: MetaDataService,
    private readonly teamService: TeamService,
    private readonly companyService: CompanyService,
    private readonly routeParamsService: RouteParamsService,
    private imageManipulationService: ImageManipulationService,
    private readonly toastrService: ToastrService,
    private readonly eventsService: EventsService,
  ) {}

  ngAfterViewInit(): void {
    this.onDropzoneInstantiation();
  }

  onDropzoneInstantiation(): void {
    this.onDropzoneDeclaration();
    this.onDropzoneDimensionCheck();
    this.onDropzoneSuccess();
    this.onDropzoneAddedFile();
    this.onDropzoneMaxFileAdded();
    this.onDropzoneError();

    if (this.isTrialGuideUpload) {
      this.uploadExistingLogo();
    }
  }

  submitDetails(form?: NgForm): void {
    if (!form.valid || !this.isLogoUploadedOrError) {
      return;
    }
    this.submitLogoColorCode();
  }

  skipStep(): void {
    if (!this.isTrialGuideUpload) {
      this.eventsService.trackEvent(EventTypes.CtaClicked, {
        Context: 'Sign Up Skip Logo',
      });
    }
    this.activeModal.close();
  }

  private submitLogoColorCode(): void {
    const currentTeam = this.teamService.getCurrentTeam() as TeamWeb;
    const companyId = currentTeam.companyMetadata._id;
    const companyLogo = this.model.companyLogo || this.fallbackLogo;

    this.isProcessing = true;
    this.metadataService
      .updateLogoColorCode(companyId, this.model.colorCode, companyLogo)
      .pipe(
        tap(() => this.activeModal.close()),
        catchError((error) => {
          this.isProcessing = false;
          this.errorHandler.handle(error);
          return throwError(() => new Error(error));
        }),
      )
      .subscribe(() => {
        if (this.isTrialGuideUpload) {
          this.toastrService.successByKey('logoUploadedSuccessfully');
        }
        this.updateCompanyInRoute(companyId);
        this.isProcessing = false;
      });
  }

  updateCompanyInRoute(companyId: string): void {
    this.companyService.getCompany(companyId).subscribe((company) => {
      this.routeParamsService.set('company', company);
    });
  }

  trackLogoUpload(): void {
    this.eventsService.trackEvent(EventTypes.CtaClicked, {
      Context: 'Sign Up Upload Logo',
    });
  }

  get isLogoUploadedOrError() {
    return this.dzProperties.uploaded || this.isDzError();
  }

  uploadNewLogo(): void {
    this.trackLogoUpload();
    this.dropzone.hiddenFileInput.click();
  }

  onDropzoneDeclaration(): void {
    if (this.dropzone) {
      this.dropzone.destroy();
    }

    this.dropzone = new Dropzone('.dz-signup', {
      url: `${environment.apiUrl}users/sign-up/logo-upload`,
      acceptedFiles: 'image/jpg, image/bmp, image/jpeg, image/png',
      headers: {
        'Time-Zone': (moment as any).tz.guess(),
      },
      maxFileSize: 5,
      maxFiles: 1,
      createImageThumbnails: true,
      thumbnailWidth: '100%',
      thumbnailMethod: 'contain',
    });
  }

  onDropzoneDimensionCheck(): void {
    this.dropzone.on('thumbnail', (file: esmDropzone.DropzoneFile) => {
      const isGreaterThanMaxDimension = file.width > this.maxResolution || file.height > this.maxResolution;
      const isLessThanMinDimension = file.width < this.minResolution || file.height < this.minResolution;

      if (isGreaterThanMaxDimension) {
        this.imageManipulationService
          .resizeImage(file, this.maxResolution, this.maxResolution)
          .then((resizedFile) => {
            this.dropzone.removeFile(file);
            this.dzProperties.uploaded = false;
            this.dropzone.addFile(resizedFile);
          })
          .catch((error) => {
            this.errorHandler.handleForDebug(error);
          });
      }

      if (isLessThanMinDimension) {
        this.dzProperties.error = {
          isDimensionError: true,
          message: 'File must be greater than 100x100 pixels',
        };
        this.dzProperties.uploaded = false;
        this.dropzone.removeFile(file);
      }
    });
  }

  onDropzoneSuccess(): void {
    this.dropzone.on('success', (file: esmDropzone.DropzoneFile, response) => {
      this.eventsService.trackEvent(EventTypes.LogoUploaded, {
        Context: this.isTrialGuideUpload ? 'Trial Start Guide' : 'Sign Up Form',
      });
      this.dzProperties.uploaded = true;
      this.model.companyLogo = response.location;
      const filePreviewHTML = file.previewElement.innerHTML;
      this.dzProperties.lastUploadedFilePreview = filePreviewHTML;
    });
  }

  onDropzoneAddedFile(): void {
    this.dropzone.on('addedfile', () => {
      this.dragStart = false;
      this.dzProperties.error = {
        isDimensionError: false,
        message: '',
      };
      const { files } = this.dropzone;
      if (files.length > 1) {
        this.dropzone.removeFile(files[0]);
      }
      this.dzProperties.uploading = true;
    });
  }

  onDropzoneMaxFileAdded(): void {
    this.dropzone.on('maxfilesreached', () => {
      if (this.dragStart) {
        this.dropzone.removeEventListeners();
      }
    });
  }

  onDropzoneError(): void {
    this.dropzone.on(
      'error',
      (providedFile: esmDropzone.DropzoneFile, response: { message: string; statusCode: string }) => {
        if (response && response.message) {
          this.errorHandler.handle({ status: response.statusCode, error: response.message }, null, null, {
            enableHTML: true,
            toastLife: 5 * 60 * 1000,
          });
        }

        const initialErrorState = this.dzProperties.error?.message === '';
        this.dzProperties.uploaded = false;
        this.dropzone.removeFile(providedFile);
        if (initialErrorState) {
          this.dzProperties.error = {
            isDimensionError: false,
            message: JSON.stringify(response).replace(/"/g, ' '),
          };
        }
        this.dzProperties.lastUploadedFilePreview = providedFile.previewElement.innerHTML;
      },
    );
  }

  minimizeDz(): void {
    this.dragStart = false;
    const [file] = this.dropzone.getAcceptedFiles();
    if (file) {
      file.previewElement.innerHTML = this.dzProperties.lastUploadedFilePreview;
    }
  }

  getUploadingDzStyles(): Record<string, string> | string {
    const isButtonFileUpload = this.dzProperties.uploading && !this.dragStart;
    return isButtonFileUpload ? { background: 'transparent', border: 'none', cursor: 'default' } : '';
  }

  isDzError(): boolean {
    const isError = this.dzProperties.error?.message !== '';
    return isError;
  }

  ngOnDestroy(): void {
    if (this.dropzone) {
      this.dropzone.destroy();
    }
  }

  @HostListener('dragenter', ['$event'])
  onDragOver(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    this.dragStart = true;

    if (this.dropzone.files.length > 0) {
      const latestFile = this.dropzone.files[0];
      latestFile.previewElement.innerHTML = '';
    }
  }

  uploadExistingLogo(): void {
    this.model.colorCode = this.colorCode;
    if (this.companyLogo !== this.fallbackLogo) {
      this.model.companyLogo = this.companyLogo;
      this.emitExistingLogoToDropzone();
    }
  }

  emitExistingLogoToDropzone() {
    const currentLogo = {
      name: 'company-logo.jpg',
      size: 123,
    };
    this.dropzone.emit('addedfile', currentLogo);
    this.dropzone.emit('thumbnail', currentLogo, this.companyLogo);
    this.dropzone.emit('complete', currentLogo);
    this.dropzone.files.push(currentLogo);
    this.dzProperties.uploaded = true;
  }
}
