import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';

import { IntroVideoStatus, SignUpSteps } from '@site-mate/dashpivot-shared-library';

import { IBusinessAttribute } from 'app/business-attributes/business-attribute.model';
import { TeamService } from 'app/shared/service/team.service';
import { SignUpIntroVideoDialogComponent } from 'app/user/sign-up-simplified/sign-up-steps/sign-up-intro-video-dialog/sign-up-intro-video-dialog.component';
import { UserService } from 'app/user/user.service';

@Injectable()
export class IntroVideoService {
  isIntroVideoModalOpen: boolean;

  constructor(
    private readonly userService: UserService,
    private readonly teamService: TeamService,
    private readonly dialog: Dialog,
  ) {}

  async showIntroVideoDialog(
    isDismissable: boolean,
    signUpStep?: SignUpSteps,
  ): Promise<DialogRef<unknown, SignUpIntroVideoDialogComponent> | void> {
    const { signUpProgress } = this.userService.getCurrentUser();

    const shouldShowTemplateReferralVideo = signUpProgress?.isComplete;
    const shouldShowNonTemplateReferralVideo =
      !signUpProgress?.isComplete && signUpStep === SignUpSteps.IntroVideo;

    const shouldShowVideo = signUpProgress?.templateId
      ? shouldShowTemplateReferralVideo
      : shouldShowNonTemplateReferralVideo;

    if (
      !shouldShowVideo ||
      signUpProgress?.introVideoStatus !== IntroVideoStatus.NotDisplayed ||
      this.isIntroVideoModalOpen
    ) {
      return Promise.resolve();
    }

    const businessVertical = await this.getBusinessVertical();
    const introVideoDialog = this.dialog.open(SignUpIntroVideoDialogComponent, {
      disableClose: true,
      data: {
        dismissable: isDismissable,
        businessVertical,
      },
    });
    this.isIntroVideoModalOpen = true;

    introVideoDialog.closed.subscribe(() => {
      this.isIntroVideoModalOpen = false;
    });

    return introVideoDialog;
  }

  private async getBusinessVertical(): Promise<Partial<IBusinessAttribute>> {
    let vertical: IBusinessAttribute;
    const teamId = this.userService.getCurrentUser().signUpProgress?.teamId;

    if (teamId) {
      const company = await lastValueFrom(this.teamService.getCompanyByTeamId(teamId));
      vertical = { _id: company.businessAttributes?.[0] } as IBusinessAttribute;
    }

    return vertical;
  }
}
