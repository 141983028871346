import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { IBusinessAttribute } from 'app/business-attributes/business-attribute.model';

import { SelectorTypes } from '../selector-types.enum';

@Component({
  selector: 'cc-vertical-role-typeahead',
  templateUrl: './vertical-role-typeahead.component.html',
  styleUrls: ['./vertical-role-typeahead.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VerticalRoleTypeaheadComponent implements OnInit, OnDestroy {
  @Input() options: IBusinessAttribute[] = [];

  @Input() selectorForm: UntypedFormGroup;
  @Input() disableTypeahead: boolean;
  @Input() typeaheadType: SelectorTypes;
  @Input() placeholder: string;

  @Output() selectionConfirm = new EventEmitter<string>();
  @Output() customValueConfirm = new EventEmitter();

  @ViewChild('searchTypeahead') private readonly typeahead: NgbTypeahead;

  typeaheadInputType: string;

  readonly limitForResults = 10;
  readonly characterTriggerLimit = 4;
  readonly rejectStringsWithOnlyWhiteSpaces = /^(\s+\S+\s*)*(?!\s).*$/;

  ngOnInit() {
    this.typeaheadInputType = `${this.typeaheadType}.otherOption`;
    this.makeRequired();
  }

  constructor(protected readonly ref: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this.resetValidators();
  }

  resultFormatter(result: IBusinessAttribute): string {
    return result.name;
  }

  inputFormatter = (input: IBusinessAttribute | string) => (input as IBusinessAttribute)?.name || input;

  onSelect({ item }: { item: IBusinessAttribute }) {
    this.selectionConfirm.emit(item.code);
  }

  submitCustomValue() {
    const formOption = this.selectorForm.get(`${this.typeaheadType}.otherOption`);
    formOption.markAsDirty();
    this.customValueConfirm.emit();
  }

  onSearch: OperatorFunction<string, readonly IBusinessAttribute[]> = (input: Observable<string>) =>
    input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((userInput) =>
        userInput.length >= this.characterTriggerLimit && !this.disableTypeahead
          ? this.options.filter(({ name }) => name.toLowerCase().includes(userInput.toLowerCase()))
          : [],
      ),
    );

  typeaheadKeydown() {
    if (!this.typeahead.isPopupOpen()) {
      return;
    }

    this.ref.detectChanges();

    const popup = document.getElementById(this.typeahead.popupId);
    const activeElements = popup.getElementsByClassName('active');
    if (activeElements.length === 1) {
      const element = <HTMLElement>activeElements[0];
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }

  get isErrorVisible(): boolean {
    const input = this.selectorForm.get(this.typeaheadInputType);
    return !input.valid && (input.dirty || input.touched);
  }

  get isRequiredErrorVisible(): boolean {
    const input = this.selectorForm.get(this.typeaheadInputType);
    return !input.valid && (input.errors?.required || input.errors?.pattern);
  }

  private makeRequired(): void {
    const formOption = this.selectorForm.get(`${this.typeaheadType}.otherOption`);
    formOption.setValidators([
      Validators.required,
      Validators.pattern(this.rejectStringsWithOnlyWhiteSpaces),
    ]);
    formOption.updateValueAndValidity();
  }

  private resetValidators(): void {
    const formOption = this.selectorForm.get(`${this.typeaheadType}.otherOption`);
    formOption.clearValidators();
    formOption.updateValueAndValidity();
    formOption.markAsPristine();
    formOption.markAsUntouched();
  }
}
