import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, lastValueFrom, takeUntil } from 'rxjs';

import { SignUpSteps } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { UserService } from 'app/user/user.service';

import { SignUpService } from '../../sign-up.service';

@Component({
  selector: 'cc-sign-up-user-signature',
  templateUrl: './sign-up-user-signature.component.html',
  styleUrls: ['./sign-up-user-signature.component.scss', '../../sign-up.component.scss'],
})
export class SignUpUserSignatureComponent implements OnInit {
  userSignatureForm!: FormGroup;

  isProcessing = false;

  model = { firstName: '', lastName: '' };

  signatureCanvasOptions: Record<string, number> = {
    canvasWidth: 280,
    canvasHeight: 60,
  };
  unSubscribe = new Subject<void>();

  constructor(
    readonly signupService: SignUpService,
    private readonly appUtilService: AppUtilService,
    private readonly globalApiService: GlobalApiService,
    public readonly activeModal: NgbActiveModal,
    private readonly errorHandler: ErrorHandler,
    private readonly userService: UserService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    const currentUser = this.userService.getCurrentUser();
    this.setSignatureCanvasOptions();
    this.subscribeToSignupProcessing();

    this.model.firstName = currentUser?.firstName;
    this.model.lastName = currentUser?.lastName;

    this.userSignatureForm = this.formBuilder.group({
      name: ['', Validators.required],
      signature: ['', Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  subscribeToSignupProcessing() {
    this.signupService.isProcessing$.pipe(takeUntil(this.unSubscribe)).subscribe((isProcessing) => {
      this.isProcessing = isProcessing;
    });
  }

  setSignatureCanvasOptions(): void {
    const { isSmallWindow } = this.appUtilService;
    if (isSmallWindow) {
      this.signatureCanvasOptions = {
        canvasWidth: 260,
        canvasHeight: 70,
      };
    }
  }

  async onFinalSubmit() {
    const { valid, controls } = this.userSignatureForm;
    if (valid && controls.signature.valid && controls.name.valid) {
      try {
        this.signupService.setProcessing(true);
        const signatureFile = this.appUtilService.dataUrlToFile(controls.signature.value, 'signature');

        const { url: signature } = await lastValueFrom(
          this.globalApiService.uploadSignature<{ url: string }>(signatureFile, controls.signature.value),
        );

        await lastValueFrom(this.userService.updateCurrentUser());

        const currentUser = this.userService.getCurrentUser();
        if (this.signupService.isNonTemplateReferral && !currentUser?.signUpProgress?.templateId) {
          this.signupService.setActiveStep(SignUpSteps.IntroVideo);
        }

        this.activeModal.close({ signature });
      } catch (error) {
        this.signupService.setProcessing(false);

        // @ts-expect-error implicitly has an 'any' type
        if (error.status !== 0) {
          this.errorHandler.handle(error);
        }
      }
    }
  }
}
