/*
 * Enum for the possible states of a YouTube video.
 * https://developers.google.com/youtube/iframe_api_reference#Playback_status
 */
export enum VideoStates {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}
