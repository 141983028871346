import { Component, OnInit } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { AuthService } from 'app/auth/auth.service';
import { SegmentService } from 'app/segment/segment.service';
import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-no-workspace-found',
  templateUrl: './no-workspace-found.component.html',
  styleUrls: ['./no-workspace-found.component.scss'],
})
export class NoWorkspaceFound implements OnInit {
  constructor(
    private readonly authService: AuthService,
    private readonly segmentService: SegmentService,
    private readonly toastService: ToastrService,
  ) {}

  ngOnInit(): void {
    this.toastService.clear();
  }

  logout() {
    void EventNotifierService.notify(new DashpivotEvent(EventTypes.UserLoggedOut), this.segmentService);
    return this.authService.logout();
  }
}
