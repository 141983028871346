<div class="vertical-role-function-job-selector selector-container sm-flex sm-flex-col">
  <div class="header">
    <h1 class="sm-text-xl sm-font-bold">{{ heading }}</h1>
    <span class="my-4 d-block">{{ subtitle }}</span>
  </div>
  <form [formGroup]="selectorForm" (ngSubmit)="submitForm()" novalidate>
    <ngb-accordion [closeOthers]="true" activeIds="panel-0" class="block" #accordion="ngbAccordion">
      <ngb-panel
        id="panel-{{ index }}"
        *ngFor="let panel of accordionPanels; index as index"
        [disabled]="index && !selectedVertical"
      >
        <ng-template ngbPanelHeader let-isOpen="opened">
          <button
            type="button"
            ngbPanelToggle
            class="btn cc-btn-transparent d-flex align-items-center justify-content-between animate-fade-in w-100"
          >
            <span
              data-test="accordion-title"
              class="d-flex flex-column flex-sm-row align-items-center font-weight-bold text-md-left"
            >
              {{ panel.header }}
              <div
                data-test="selector-value"
                class="mt-2 ml-0 ml-sm-2 mt-sm-0 font-weight-normal animate-fade-in"
              >
                {{
                  selectorForm.value[panel.type].standardOption.name ||
                    selectorForm.value[panel.type].otherOption
                }}
                <span class="error-state" *ngIf="finishButtonClicked && selectorForm.get(panel.type).invalid">
                  <span
                    i18n
                    class="align-middle sm-text-default-red"
                    *ngIf="
                      selectorForm.controls[panel.type].controls.standardOption?.errors?.required ||
                      selectorForm.controls[panel.type].controls.otherOption?.errors?.required
                    "
                  >
                    Required
                  </span>
                </span>
              </div>
            </span>
            <i
              class="pl-3 cc-icon cc-icon-check-circle animate-fade-in pl-sm-0"
              *ngIf="panel.type === selectorTypes.Vertical ? !!selectedVertical : !!selectedRole"
            ></i>
          </button>
        </ng-template>
        <ng-template ngbPanelContent>
          <!-- Verticals -->
          <div *ngIf="panel.type === selectorTypes.Vertical" class="input-container" formGroupName="vertical">
            <div
              data-test="vertical-input"
              class="d-flex"
              *ngFor="let option of verticalOptions; index as verticalIndex"
              [class.other-option]="option.code === otherOption.code"
              [class.align-items-center]="option.code !== otherOption.code"
            >
              <div
                class="px-3 py-2 radio-input d-flex align-items-center"
                (change)="$event.preventDefault(); selectorClick(panel.type, option.code)"
                id="radio-{{ verticalIndex }}"
                [class.active]="
                  option.code === otherOption.code && selectedVertical?.code === otherOption.code
                "
              >
                <input
                  type="radio"
                  [id]="option.code"
                  formControlName="standardOption"
                  [value]="option.code === otherOption.code ? otherOption.code : option"
                  required
                />
                <label [for]="option.code" class="default-label pl-2 mb-0"> {{ option.name }} </label>
              </div>
              <!-- Vertical Other Option -->
              <div
                #verticalTypeahead
                *ngIf="option.code === otherOption.code && this.otherVerticalSelected"
                class="mt-2 d-flex flex-column"
              >
                <cc-vertical-role-typeahead
                  id="verticalTypeahead"
                  placeholder="Industry"
                  [options]="verticalOptions"
                  [selectorForm]="selectorForm"
                  [typeaheadType]="selectorTypes.Vertical"
                  (selectionConfirm)="onVerticalTypeaheadClick($event)"
                  (customValueConfirm)="submitCustomVerticalValue()"
                ></cc-vertical-role-typeahead>
              </div>
            </div>
          </div>
          <!-- Roles -->
          <div
            *ngIf="panel.type === selectorTypes.Role"
            class=""
            data-test="role-input-container"
            formGroupName="role"
          >
            <div
              data-test="role-input"
              class="d-flex"
              *ngFor="let option of roleOptions; index as roleIndex"
              [class.other-option]="option.code === otherOption.code"
              [class.align-items-center]="option.code !== otherOption.code"
            >
              <div
                class="px-3 py-2 radio-input d-flex align-items-center"
                id="radio-{{ roleIndex }}"
                (change)="$event.preventDefault(); selectorClick(panel.type, option.code)"
                [class.active]="option.code === otherOption.code && selectedRole?.code === otherOption.code"
              >
                <input
                  type="radio"
                  [id]="option.code"
                  formControlName="standardOption"
                  [value]="option.code === otherOption.code ? otherOption.code : option"
                  required
                />
                <label [for]="option.code" class="default-label pl-2 mb-0">
                  {{ option.name }}
                  <span class="sm-text-secondary-text" *ngIf="option.description">
                    - {{ option.description }}</span
                  ></label
                >
              </div>
              <!-- Role Other Option -->
              <div
                #roleTypeahead
                *ngIf="option.code === otherOption.code && this.otherRoleSelected"
                class="mt-2 d-flex flex-column"
              >
                <cc-vertical-role-typeahead
                  id="roleTypeahead"
                  placeholder="Role"
                  [options]="roleOptions"
                  [typeaheadType]="selectorTypes.Role"
                  [selectorForm]="selectorForm"
                  [disableTypeahead]="disableTypeahead"
                  (selectionConfirm)="onRoleTypeaheadClick($event)"
                  (customValueConfirm)="submitCustomRoleValue()"
                ></cc-vertical-role-typeahead>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
    <div class="mt-4 d-flex justify-content-center">
      <button
        type="submit"
        id="btn-submit-form-vrfj-selector"
        [disabled]="isSubmitted"
        [ccBusyButton]="isLoading"
        class="py-3 btn btn-submit btn-primary"
      >
        <span i18n>Next</span>
      </button>
    </div>
  </form>
</div>
