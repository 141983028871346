<img src="/assets/images/user/no-results.svg" alt="No results" class="no-results-img sm-m-auto" />

<p class="sm-my-4 sm-text-xl sm-font-bold sm-text-default-text" i18n>No workspace available</p>

<p i18n class="sm-text-base">
  You don’t have access to any teams, projects or workspaces. Please contact your admin to gain access.
</p>

<button type="button" class="btn logout-button" (click)="logout()">
  <i class="cc-icon cc-icon-logout" aria-hidden="true"></i>
  <span i18n>Logout</span>
</button>
