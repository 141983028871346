import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';

import { EventTypes, IntroVideoStatus } from '@site-mate/dashpivot-shared-library';

import { IBusinessAttribute } from 'app/business-attributes/business-attribute.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { UserService } from 'app/user/user.service';

import { introVideos } from './intro-videos';
import { VideoStates } from './youtube-video-player/youtube-video-states.enum';

@Component({
  selector: 'cc-sign-up-intro-video-dialog',
  templateUrl: './sign-up-intro-video-dialog.component.html',
})
export class SignUpIntroVideoDialogComponent {
  protected INTRO_VIDEO_ID = 'PkCXYOHUXfY';
  dismissable: boolean;
  title: string;
  businessVertical: IBusinessAttribute;
  introVideosList = introVideos;

  private hasVideoStarted = false;
  @Output() dismissEvent = new EventEmitter<void>();

  constructor(
    @Inject(DIALOG_DATA) public data: { dismissable: boolean; businessVertical: IBusinessAttribute },
    private readonly dialogRef: DialogRef,
    private readonly errorHandler: ErrorHandler,
    private readonly eventsService: EventsService,
    private readonly userService: UserService,
  ) {
    this.dismissable = this.data.dismissable;
    this.title = 'Welcome to Dashpivot';
    this.businessVertical = this.data.businessVertical;
  }

  ngOnInit() {
    this.setVideoIdFromVertical();
  }

  setVideoIdFromVertical() {
    if (this.businessVertical) {
      const introVideoLink = this.introVideosList.find(
        (video) => video.verticalId === this.businessVertical._id,
      )?.link;
      this.INTRO_VIDEO_ID = introVideoLink ? introVideoLink.split('/').pop() : this.INTRO_VIDEO_ID;
    }
  }

  onVideoStateChange(event: Partial<{ data: VideoStates }>) {
    if (event.data === VideoStates.PLAYING && !this.hasVideoStarted) {
      this.hasVideoStarted = true;
      this.eventsService.trackEvent(EventTypes.VideoPlayed, {
        Context: 'Dashpivot Intro',
      });
    }
  }

  changeIntroVideoStatus() {
    const currentUser = this.userService.getCurrentUser();
    if (currentUser.signUpProgress.templateId) {
      const updatedUser = {
        ...currentUser,
        signUpProgress: {
          ...currentUser.signUpProgress,
          introVideoStatus: IntroVideoStatus.Displayed,
        },
      };

      this.userService.updateProfile(updatedUser).subscribe({
        next: () => {
          this.userService.setCurrentUser(updatedUser);
        },
        error: (error) => this.errorHandler.handle(error),
      });
    }
  }

  onDismiss() {
    this.changeIntroVideoStatus();
    this.dialogRef.close();
  }

  onCTAClick() {
    this.changeIntroVideoStatus();
    this.dialogRef.close();
  }
}
