import { Injectable } from '@angular/core';

import { ErrorHandler } from 'app/shared/service/error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class ImageManipulationService {
  constructor(private readonly errorHandler: ErrorHandler) {}

  resizeImage(file: File, maxWidth: number, maxHeight: number, quality = 0.7): Promise<File> {
    // eslint-disable-next-line promise/avoid-new
    return new Promise((resolve) => {
      const image = new Image();
      const objectURL = URL.createObjectURL(file);
      image.src = objectURL;

      image.onload = async () => {
        const resizedFile = await this.processImageResize(image, file, maxWidth, maxHeight, quality);
        URL.revokeObjectURL(objectURL);
        resolve(resizedFile);
      };

      image.onerror = () => {
        URL.revokeObjectURL(objectURL);
        this.errorHandler.handleForDebug(new Error('Failed to load image for resize'));
        resolve(file);
      };
    });
  }

  private async processImageResize(
    image: HTMLImageElement,
    file: File,
    maxWidth: number,
    maxHeight: number,
    quality: number,
  ): Promise<File> {
    if (image.width <= maxWidth && image.height <= maxHeight) {
      return file;
    }

    const { width, height } = this.calculateResizedDimensions(image, maxWidth, maxHeight);
    const canvas = this.createCanvas(width, height);
    this.drawImage(canvas, image, width, height);

    try {
      const mimeType = this.getOutputMimeType(file);
      const blob = await this.convertCanvasToBlob(canvas, mimeType, quality);
      return new File([blob], file.name, { type: mimeType, lastModified: Date.now() });
    } catch (error) {
      this.errorHandler.handleForDebug(error);
      return file;
    }
  }

  private calculateResizedDimensions(
    image: HTMLImageElement,
    maxWidth: number,
    maxHeight: number,
  ): { width: number; height: number } {
    let { width, height } = image;

    if (width > height && width > maxWidth) {
      height = Math.round(height * (maxWidth / width));
      width = maxWidth;
    } else if (height > maxHeight) {
      width = Math.round(width * (maxHeight / height));
      height = maxHeight;
    }

    return { width, height };
  }

  private createCanvas(width: number, height: number): HTMLCanvasElement {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
  }

  private drawImage(canvas: HTMLCanvasElement, image: HTMLImageElement, width: number, height: number): void {
    const canvasContext = canvas.getContext('2d');
    canvasContext.drawImage(image, 0, 0, width, height);
  }

  private getOutputMimeType(file: File): string {
    const supportedTypes = ['image/jpeg', 'image/webp', 'image/jpg', 'image/png', 'image/bmp'];
    return supportedTypes.includes(file.type) ? file.type : 'image/jpeg';
  }

  private async convertCanvasToBlob(
    canvas: HTMLCanvasElement,
    mimeType: string,
    quality: number,
  ): Promise<Blob> {
    // eslint-disable-next-line promise/avoid-new
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Unable to convert canvas to blob'));
          }
        },
        mimeType,
        quality,
      );
    });
  }
}
