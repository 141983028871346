<div [formGroup]="selectorForm">
  <div class="input-group" [formGroupName]="typeaheadType">
    <input
      #searchTypeahead="ngbTypeahead"
      type="text"
      class="form-control"
      data-test="typeahead-input"
      formControlName="otherOption"
      focusFirst="false"
      popupClass="d-inline vertical-role-typeahead"
      container="body"
      i18n-placeholder
      [placeholder]="placeholder"
      required
      [name]="typeaheadType"
      [ngbTypeahead]="onSearch"
      [inputFormatter]="inputFormatter"
      [resultFormatter]="resultFormatter"
      [class.error-state]="
        !selectorForm.get(typeaheadInputType).valid &&
        (selectorForm.get(typeaheadInputType).dirty || selectorForm.get(typeaheadInputType).touched)
      "
      (selectItem)="onSelect($event)"
      (keyup.enter)="submitCustomValue()"
      (keydown)="typeaheadKeydown()"
    />
    <button
      class="btn cc-btn-primary-constructive btn-add-option text-white px-3"
      data-test="typeahead-input-confirm"
      type="button"
      (click)="submitCustomValue()"
      [class.disabled-button]="!selectorForm.get(typeaheadInputType).valid"
    >
      <span class="cc-icon cc-icon-check"></span>
    </button>
  </div>
</div>
<div *ngIf="this.isErrorVisible">
  <span data-test="typeahead-error-state" class="sm-text-default-red" i18n *ngIf="isRequiredErrorVisible">
    Required
  </span>
</div>
